.header {
	/* background: linear-gradient(#0171a1, #0084bd); */
	background-image: linear-gradient(180deg, #0171a1, #0084bd);
	/* padding: 40px 60px; */
	padding: 25px 64px 10px;
	min-height: 130px;

	.header-row {
		display: flex;
		justify-content: space-between;
	}

	&.glossary-page {
		.header-btm-div {
			.heading-h {
				font-size: 56px;
				margin-top: 16px;
				margin-bottom: 4px;

				&.glossary-details-h {
					margin: 0 0 16px !important;
				}
			}

			.header-p {
				margin-top: 12px !important;
				margin-bottom: 0 !important;
				color: #ffffffc7;
				font-size: 18px;

				&.glossary-details-p {
					margin: 4px 0 16px !important;
					max-width: 1018px;
					line-height: 24px;
				}
			}

			.btm-search-div {
				margin-bottom: 18px;

				.search-Header-btn {
					border-color: #f7f7f791 !important;
				}
			}
		}
	}
}
.header-link {
	display: flex;
	background: none;
	background: none;
	align-items: center;
	margin-top: 20px;
	border: none;
	.header-link-text {
		font-size: 24px;
		color: #fff;
	}
}

.custom-btn {
	background: none;
	border: none;
	display: none;
}
.internal-btn {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 40px;
	min-width: 108px;
	font-family: "Inter", sans-serif;
	font-size: 16px;
	color: #fff;
	background-color: transparent;
	border: none;
	font-weight: 500;
	border: 1px solid #fff !important;
	border-radius: 4px;
	padding: 0 8px;
	margin-right: 24px;
}
.header-row-right {
	display: flex;
	align-items: center;
}
.language {
	/* margin-left: 10px;
  color: #fff;
  font-weight: 500; */
	font-size: 14px;
}
.search-input {
	padding: 10px;
	border-radius: 4px;
	background: transparent;
	border: none;
	font-size: 16px;
	font-weight: 400;
	width: 320px;
	font-family: "Inter", sans-serif;
}
.search-input:focus {
	outline: none;
}
.search-input::placeholder {
	color: #9ca5b4;
}
.heading-h {
	color: #fff;
	font-weight: 600;
	font-size: 36px;
	text-align: center;
	/* margin: 20px 0; */
	margin: 26px 0 14px;
	font-family: "Inter", sans-serif;
}
.header-p {
	font-size: 20px;
	font-weight: 400;
	color: #fff;
	/* margin-top: -20px; */
	margin: 26px 0 0;
	text-align: center;
	font-family: "Inter", sans-serif;
}

.log-btn {
	display: flex;
	align-items: center;
	/* margin-left: 10px; */
	background: none;
	border: none;
	padding: 0;
}
.user-name {
	max-width: 130px;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	margin: 0;
	font-size: 14px;
	color: #fff;
	font-family: "Inter", sans-serif;
	margin-bottom: 0;
	text-align: start;
}

.user-address {
	max-width: 130px;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	margin: 0;
	font-size: 14px;
	color: #fff;
	font-family: "Inter", sans-serif;
	margin-bottom: 0;
	text-align: start;
}
.user-ic {
	font-size: 28px;
	color: #f1f6fa;
}
.floating-div {
	background-color: #fff;
	position: absolute;
	right: 0;
	left: 1;
	width: 95%;
	margin-top: 1px;
	border-radius: 4px;
	padding: 2x 3px;
	border: none;
}
.logout-btn {
	background-color: #fff;
	position: absolute;
	right: 0;
	left: 1;
	width: 95%;
	margin-top: 1px;
	border-radius: 4px;
	display: flex;
	align-items: center;
	justify-content: center;
	border: none;
}
.logout-btn:hover {
	background: #2f83a7;
}
.lng-txt-m {
	margin-left: 5px;
	color: #fff;
	font-weight: 400;
	font-size: 14px;
}
.lng-txt {
	font-weight: 400;
	font-size: 14px;
}

.lng-icon {
	font-size: 20px;
	color: #fff;
}
.lng-left-ic {
	font-size: 14px;
	color: #fff;
}
.btn-lng-sub {
	border: none;
	background: none;
	margin-left: 5px;
}
.mobi-icon {
	font-size: 24px;
	color: #fff;
}

.mobile-nav {
	position: absolute;
	background: #0171a1;
	top: 75px;
	left: 0;
	right: 0;
	/* top: 100px; */
	padding: 0 14px;
	border-radius: 4px;
}
.header-btm-div {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.search-btn-g {
	padding: 8px 30px;
	background-color: #fff;
	border-radius: 4px;
	border: none !important;
	margin-left: 10px;
	color: #4a4a4a;
	font-size: 14px;
	font-weight: 400;
	cursor: pointer;
	height: 42px;
	font-family: "Inter", sans-serif;
}

.search-Header-btn {
	border: 1px solid #ebf2f547;
	display: flex;
	border-radius: 4px;
	align-items: center;
	height: 40px;

	img {
		padding-left: 8px;
	}
	/* dis */
}
.btm-search-div {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-top: 24px;
	height: 45px;

	.right-btn-txt {
		color: #fff;
		font-size: 20px;
		font-weight: 600;
		display: flex;
		justify-content: flex-start;
		align-items: center;

		img {
			padding-left: 2px;
		}
	}
}
@media (max-width: 786px) {
	.header {
		padding: 30px 24px !important;

		.header-link {
			margin-top: 16px;

			.header-link-text {
				font-size: 20px !important;
			}

			.p-back {
				font-size: 14px !important;
			}
		}

		.heading-h {
			font-size: 26px;
			margin-top: 24px !important;
		}

		.header-p {
			font-size: 16px;
		}
	}
	.header-row {
		border-bottom: none !important;

		.shifl-logo {
			height: 25px !important;
		}
	}
	.header-row-right {
		display: none;
	}
	.custom-btn {
		display: block;
	}
	.btm-search-div {
		display: flex;
		margin-top: 16px;
		width: 100%;

		.search-Header-btn {
			width: 100%;
		}

		.search-input {
			width: 100%;
			font-size: 14px;
			text-overflow: ellipsis;
		}
	}
}