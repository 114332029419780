.app-container {
	display: flex;
	flex-direction: column;
	min-height: 100vh;
	background-color: #f7f7f7;
}

.main-div-g {
	/* padding: 20px 10%; */
	padding: 20px 64px;
	background-color: #f7f7f7;
	min-height: 400px;
}

.main-div-items {
	margin-bottom: 16px;
}

.alphabet {
	font-size: 50px;
	font-weight: 600;
	font-family: "Inter", sans-serif;
	color: #000000;
	/* line-height: 30px; */
	margin: 0 0 16px;
}

.grid-row {
	display: grid;
	column-gap: 18px;
	grid-template-columns: 1fr 1fr 1fr;
}

.grid-row .item-div {
	background-color: #fff;
	padding: 26px 24px;
	border-radius: 4px;
	margin-bottom: 18px;
	cursor: pointer;
	/* border: 1px solid #fff; */
}

.grid-row .item-div:hover {
	background-color: #f0fbff;
	border-radius: 4px;
}

.grid-row .item-div .item {
	font-size: 20px;
	color: #000000;
}

.grid-row .item-div .item .item-p {
	margin: 0 !important;
	font-weight: 500;
	font-family: "Inter", sans-serif;
}

.hover-item {
	background-color: #f0fbff;
	border-radius: 4px;
	/* border: 1px solid #0171a1; */
}

::placeholder {
	color: #b4cfe0;
	font-family: "Inter", sans-serif;
}

@media (min-width: 787px) and (max-width: 942px) {
	.item {
		height: 90px;
	}
}
@media (max-width: 786px) {
	.main-div-g {
		padding: 24px;

		.main-div-items {
			margin-bottom: 46px;
		}

		.alphabet {
			margin: 0 0 16px !important;
			font-size: 32px;
		}
	}

	.grid-row {
		grid-template-columns: 1fr;
	}

	.grid-row .item-div {
		padding: 24px;
	}

	.grid-row .item-div .item {
		font-size: 16px;
	}
}