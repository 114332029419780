button {
	cursor: pointer;
}
a {
	text-decoration: none;
}

body,
html {
	height: 100%;
	margin: 0;
}

#root {
	height: 100%;
}
