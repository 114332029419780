.container {
	background-color: #f1f6fa;
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	height: 100vh;
	.login-div {
		width: 325px;
		margin: 0 auto;
		background: #ffffff;
		border-radius: 4px;
		padding: 24px;
	}
}
.heading {
	align-self: center;
	font-weight: 600;
	font-size: 20px;
	color: #4a4a4a;
	margin-top: 8px;
}
.input {
	padding: 6px 10px;
	width: 93%;
	border: 1px solid #b4cfe0;
	background-color: #fff !important;
	border-radius: 4px;
	min-height: 30px !important;
	font-size: 14px;
	color: #4a4a4a;
	font-family: "Inter", sans-serif;
	outline: none !important;
}
.label {
	font-size: 12px;
	color: #819fb2;
	font-family: "Inter", sans-serif;
	font-weight: 600;
	width: 100%;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	margin-bottom: 6px;
}
.form-div {
	margin-top: 15px;
}

.form-div-lower {
	margin-top: 15px;
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.checkbox {
	width: 17px;
}
.forget-btn {
	background: none;
	border: none;
	font-family: "Inter", sans-serif;
}
.err-msg {
	align-self: center;
	margin: 20px 0px;
	font-weight: 500;
	font-size: 14px;
	color: #e85b23;
}
.visibility_btn {
	border: none;
	background: none;
}
.password_div {
	display: flex;
	padding: 6px 10px;
	width: 93%;
	border: 1px solid #b4cfe0;
	background-color: #fff !important;
	border-radius: 4px;
	min-height: 30px !important;
	font-size: 14px;
	color: #4a4a4a;
}
.password_input {
	border: none;
	width: 90%;
	font-family: "Inter", sans-serif;
	font-size: 14px;
	letter-spacing: 0;
	outline: none !important;
}
.password_input:focus {
	outline: none !important;
}
.visibility_txt {
	color: #757575;
}
.submit-btn {
	/* margin-top: 20px; */
	padding: 1px 1px;
	background-color: #0171a1;
	border: none;
	border-radius: 4px;
	height: 40px;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 14px;
	font-family: "Inter", sans-serif;
}
.lds-dual-ring {
	display: inline-block;
	width: 30px;
	height: 30px;
	margin: 5px 0px;
}
.submit-txt {
	font-weight: 500;
	font-size: 15px;
	color: #fff;
}
.lds-dual-ring:after {
	margin-left: 25%;
	margin-top: 7%;
	content: " ";
	display: block;
	width: 20px;
	height: 20px;
	border-radius: 50%;
	border: 3px solid #f3f3f3;
	border-color: #fff transparent #fff transparent;
	/* border-color: rgb(50, 45, 39) transparent rgb(5, 4, 4) transparent; */
	animation: spin 1s linear infinite;
}
@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
@media (max-width: 600px) {
	.login-div {
		width: 50% !important;
		.heading {
			font-size: 19px;
		}
	}
}
@media (min-width: 700px) and (max-width: 950px) {
	.login-div {
		width: 40% !important;
		.heading {
			font-size: 21px;
		}
	}
}