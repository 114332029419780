.app-container {
	display: flex;
	flex-direction: column;
	min-height: 100vh;
	background-color: #f7f7f7;
}

.icon-small {
	font-size: 14px;
	color: #fff;
}
.main-div {
	display: table-row;
}
.main-div-video {
	display: table;
	width: 80%;
	background-color: #fff;
	padding: 22px;
	margin: 16px auto;
	font-family: "Inter", sans-serif;
	min-height: calc(100vh - 350px);
}
.my-button {
	background: none;
	border: none;
	padding: 0;
	margin: 0;
	display: flex;
	align-items: center;
	cursor: pointer;
	width: 100%;
	justify-content: space-between;
	height: 44px;
	text-align: start;
	padding-left: 12px;
}

.my-button.active .video-type {
	font-weight: 600;
}

.my-button.child {
	border-left: 3px solid #fff;
}

.my-button.child.active {
	font-weight: 600;
	border-left: 3px solid #1a6d9e;
	background-color: #f0f9ff;
}

.custom-icons-head {
	font-size: 20px;
	color: #819fb2;
}
.video-div {
	display: flex;
	justify-content: center;
	align-items: center;
	border: 1px solid #D8E7F0;
	border-radius: 4px;
	/* padding: 10px; */
}
.video-div-img {
	display: flex;
	justify-content: center;
	align-items: center;
}
.video-container {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.left-div {
	border: 2px solid #ebf2f5;
	border-radius: 4px;
	padding: 20px;
	margin-right: 24px;
	width: 30%;
}

.right-div {
	padding-left: 40px;
	width: 70%;
	vertical-align: top;
}

.left-div,
.right-div {
	display: table-cell;
}

.lower-tabs {
	/* padding-left: 10px; */
	border-left: 3px solid #ffffff;
}
.first-div {
	height: 44px;
	display: flex;
	align-items: center;
	padding-left: 12px;
	margin-bottom: 6px;
	border-left: 3px solid #ffffff;
}

.first-div .overview-button {
	border: none;
	background-color: transparent;
	height: 44px;
	display: flex;
	align-items: center;
	margin-bottom: 0;
	padding: 0 !important;
	text-align: start;
}

.first-div.active {
	border-left: 3px solid #1a6d9e;
	background-color: #f0f9ff;
}

.first-div.active .heading-left {
	color: #1a6d9e;
	font-weight: 600;
}

.search-btn {
	display: flex;
	align-items: center;
	border: 1px solid #ebf2f5;
	padding: 4px 10px;
	border-radius: 4px;
}
.upload-btn {
	border: none;
	cursor: pointer;
	/* ss */
	display: flex;
	align-items: center;
	background-color: #1a6d9e;
	justify-content: center;
	width: 140px;
	border-radius: 4px;
}
.upload-btn:first-child {
	color: #fff;
	font-size: 14px;
	font-weight: 400;
}
.upload-btn span {
	font-size: 19px;
	color: #fff;
}
.input-video {
	background-color: transparent;
	/* margin-left: 0px; */
	border: none;
	padding: 10px;
	font-size: 14px;
	color: #253041;
	width: 100%;
}
.input-video::after {
	color: #253041;
}
.input-video::-webkit-input-placeholder {
	/* color: red; */
	color: #b4cfe0;
}
.input-video:focus {
	outline: none;
}
.video-type {
	color: #253041;
	font-size: 14px;
	font-family: "Inter", sans-serif;
	font-weight: 400;
}
.heading-left {
	color: #253041;
	font-size: 14px;
	font-family: "Inter", sans-serif;
	font-weight: 400;
}
.video-link {
	color: #253041;
	font-size: 14px;
	margin: 10px 0;
	font-family: "Inter", sans-serif;
	font-weight: 400;
}
.custom-background {
	height: 120%;
	width: 100vw;
	background-color: rgba(0, 0, 0, 0.5);
	position: absolute;
	top: 0;
}

.modal {
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background-color: #fff;
	padding: 20px;
	border-radius: 4px;
}
.disable-scroll {
	overflow: hidden;
}
.active {
	color: #1a6d9e;
}

.video-top-row {
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.video-top-row p:first-child {
	color: #253041;
	font-weight: 600;
	font-size: 24px;
}
.video-top-row p {
	color: #6d858f;
	font-size: 14px;
	font-weight: 400;
	margin: 0;
}
.video-desc {
	color: #253041;
	font-size: 16px;
	text-align: start;
	font-family: "Inter", sans-serif;
	margin-bottom: 0;
}
.active-link {
	color: #1a6d9e;
	font-size: 14px;
	margin: 10px 0;
	font-weight: 600;
	font-family: "Inter", sans-serif;
}
.custom-icons {
	font-size: 20px;
	margin-left: 20px;
	color: #1a6d9e;
}
::placeholder {
	color: #b4cfe0;
}

@media (max-width: 768px) {
	.main-div-video {
		display: flex;
		width: 100%;
		margin: 0;
		padding: 0;

		.main-div {
			display: flex;
			flex-direction: column;
			width: 100%;

			.left-div {
				display: block;
				width: 89%;
				margin: 0;
				border: none;
				border-bottom: 1px solid #ebf2f5;
			}

			.right-div {
				display: block;
				padding: 20px;
				width: 89%;
				border-bottom: 1px solid #ebf2f5;

				.video-top-row {
					flex-direction: column;
					align-items: flex-start;
				}

				.video-top-row p:first-child {
					font-size: 20px !important;
					margin-bottom: 4px !important;
				}

				.video-top-row p:nth-child(2) {
					margin: 0 !important;
					text-align: right;
				}
			}

			.video-div iframe {
				height: 220px !important;
			}

			.video-desc {
				line-height: 20px;
				padding: 0 0 0 5px;
			}
		}
	}
}