.footer {
	/* padding: 40px 6%; */
	padding: 8px 60px 0;
	background-color: #fff;
	margin-top: auto;
}
.footer-row {
	display: flex;
	justify-content: space-between;
	border-bottom: 1px solid #ebf2f5;
	padding-bottom: 0;
}
.footer-row2 {
	display: flex;
	justify-content: center;
}
.footer-p-terms {
	color: #636363;
	font-weight: 400;
	line-height: 15px;
	margin-left: 40px;
}
.footer-p {
	color: #636363;
	font-weight: 400;
	line-height: 15px;
	font-size: 14px;
}
.btm-sub-row {
	display: flex;
	justify-content: space-around;
	align-items: center;
}
.footer-tabs {
	color: #000000;
	font-size: 14px;
	font-weight: 500;
}
.row-right {
	display: flex;
	/* justify-content: space-around; */
	justify-content: space-between;
	width: 20vw;
}
.row-right-2 {
	display: flex;
	/* justify-content: space-around; */
	justify-content: space-between;
	align-items: center;
	width: 20vw;

	a {
		font-size: 14px !important;
		p {
			margin: 0 !important;
		}
	}
}
@media (max-width: 786px) {
	.footer {
		padding: 30px 30px 6px;
	}

	.footer-row {
		display: block;
	}
	.row-right {
		display: block;
		width: 100%;
	}
	.footer-row2 {
		display: block;
		margin-top: 20px;

		.footer-p {
			font-size: 12px;
			margin-bottom: 0px;
		}
	}
	.search-btn-g {
		/* margin-top: 10px; */
		font-size: 14px;
	}
	.btm-sub-row {
		/* display: block; */
		justify-content: flex-start;

		.privacy-policy {
			margin-right: 12px;

			.footer-p {
				margin-top: 0;
			}
		}
	}
	.footer-p-terms {
		margin-left: 0px;
		font-size: 12px;
	}
}