.content-div-Word {
	/* padding: 30px 10%; */
	padding: 20px 60px 20px;
	background-color: #f7f7f7;
}
.sub-div-word {
	display: flex;
	justify-content: space-between;
}
.content-div-left {
	/* background-color: #fff; */
	width: 70%;
	margin-right: 20px;
	/* border: 1px solid #ebf2f5; */
}
.content-left-wrapper {
	background-color: #fff;
	border: 1px solid #ebf2f5;
	border-radius: 4px;
	padding: 0 32px;
}
.content-div-right {
	width: 30%;
}
.div-note {
	/* padding: 20px 10%; */
	padding: 20px 32px 60px;
	background-color: #f7f7f7;
}
.right-Heading {
	font-weight: 600;
	font-size: 20px;
	color: #4a4a4a;
	margin: 12px 0 16px;
}
.icon-div {
	display: flex;
	align-items: center;
	margin-top: -10px;
}
.p-text {
	color: #4a4a4a;
	font-weight: 400;
	font-family: 'Inter', sans-serif;
	font-size: 18px;
	line-height: 30px;
}
.lower-h {
	color: #4a4a4a;
	font-weight: 600;
	font-size: 20px;
}
.side-link {
	font-size: 14px;
	font-weight: 500;
	color: #4a4a4a;
	line-height: 20px;
}
.side-link:hover {
	text-decoration: underline;
	text-underline-offset: 2px;
}
.label-txt {
	color: #819fb2;
	font-size: 10px;
	font-weight: 700;
	margin-bottom: 6px !important;
}
.copy-btn {
	border: 1px solid #b4cfe0;
	margin: 16px 0;
	border-radius: 4px;
	width: 40%;
	height: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #fff;
}
.copy-btn p {
	color: #0171a1;
	font-weight: 500;
	font-size: 14px;
}
.icon-txt {
	font-size: 14px;
	font-weight: 500;
	margin-left: 10px;
}

.tooltip-copy {
	position: relative;
	display: inline-block;
	margin-bottom: 16px;
}

.tooltip-copy button {
	width: 99px;
	height: 40px;
	font-size: 14px;
	border: 1px solid #b4cfe0;
	border-radius: 4px;
	background-color: #ffffff;
	color: #0084bd;
	font-family: "Inter", sans-serif;
}

.tooltip-copy .tooltiptext {
	visibility: hidden;
	width: 110px;
	background-color: #4a4a4a;
	color: #fff;
	text-align: center;
	border-radius: 4px;
	padding: 8px 0;
	position: absolute;
	z-index: 1;
	top: 4px;
	left: 110%;
}

.tooltip-copy .tooltiptext::after {
	content: "";
	position: absolute;
	top: 50%;
	right: 100%;
	margin-top: -7px;
	border-width: 7px;
	border-style: solid;
	border-color: transparent #4a4a4a transparent transparent;
}

.tooltip-copy button:hover {
	background-color: #f5f9fc !important;
}

@media (max-width: 786px) {
	.content-div-Word {
		padding: 24px 16px 0;
	}
	.sub-div-word {
		display: block;
	}
	.content-div-left {
		/* width: 90%; */
		width: 100%;
		padding: 0;
		margin: 0;

		.content-div-title {
			padding: 20px;
			font-size: 20px !important;
			margin: 0;
		}

		.p-text {
			font-size: 14px;
			padding: 0 20px 20px;
			margin: 0;
		}
	}
	.content-div-right {
		margin-top: 20px;
		width: 100%;
	}
	.div-note {
		padding: 24px;

		.lower-h {
			font-size: 18px;
		}

		.lower-paragraph {
			width: 100% !important;
			font-size: 16px !important;
		}
	}
}
@media (min-width: 786px) and (max-width: 1150px) {
	.content-div-Word {
		padding: 25px 5%;
	}
	.content-div-right {
		margin-left: 20px;
	}
}