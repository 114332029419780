.header-landing {
	background-image: url("../../assets/SVG/WaterMark.svg") !important;
	/* height: 100%; */
	height: calc(100vh - 98px);
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
}

.header-content {
	padding: 0 64px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 88px;
}

.header-buttons .btn-sign {
	height: 48px;
	width: 100px;
	font-family: "Inter", sans-serif;
	font-size: 16px;
	color: #fff;
	background-color: transparent;
	border: none;
	font-weight: 500;
}

.header-buttons .btn-sign-up {
	border: 1px solid #fff !important;
	border-radius: 4px;
}

.header-row-landing {
	.shifl-logo {
		padding-top: 9px;
	}
}

.landing-body-wrapper {
	height: calc(100vh - 185px);
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}

.landing-header-div {
	.sub-heading {
		/* margin-top: -10px; */
		font-weight: 700;
		font-size: 44px;
		color: #ffffff;
		text-align: center;
		z-index: 3;
		margin: 20px 0;
	}
	.sub-heading-two {
		text-align: center;
		z-index: 3;
		font-size: 20px;
		margin: 10px auto 30px;
		line-height: 30px;
		color: #ffffff;
		width: 640px;
	}
	.left-btn {
		background: #fff;
		border-radius: 40px;
		border: none;
		padding: 0px 30px;
		z-index: 2;
		.left-btn-txt {
			color: #4a4a4a;
			font-weight: 600;
			font-size: 14px;
		}
	}
	.right-btn {
		border-radius: 40px;
		border: none;
		padding: 0px 30px;
		z-index: 2;
		margin-left: 30px;
		border: 1px solid #fff;
		.right-btn-txt {
			color: #fff;
			font-weight: 600;
			font-size: 14px;
		}
	}
}

.landing-platform-showcase {
	/* display: flex;
  justify-content: center;
  align-items: center; */
	display: table;
	margin: 0 auto;
	border-collapse: separate;
	border-spacing: 16px;

	.platform-box {
		border-radius: 8px;
		background: linear-gradient(
				78deg,
				rgba(61, 72, 92, 0.37) 0%,
				rgba(105, 117, 140, 0.11) 100%
			),
			#175878;
		background-blend-mode: multiply, normal;
		min-height: 195px;
		width: 495px;
		padding: 24px;
		border: 1px solid #0e9fdd33;
		display: table-cell;

		.platform-header {
			display: flex;
			align-items: flex-start;
			p {
				color: #fff;
				font-size: 24px;
				font-weight: 600;
				margin: 0;
				padding-left: 12px;
			}
			img {
				background: radial-gradient(
					50% 50% at 50% 50%,
					rgba(248, 213, 37, 0.28) 0%,
					rgba(248, 213, 37, 0) 100%
				);
			}
		}

		.platform-desc {
			color: #e7e9ee;
			font-size: 16px;
			line-height: 24px;
			font-family: "Inter", sans-serif;
		}
	}
}

.landing-btn {
	display: flex;
	height: 45px;
	align-items: center;

	.left-btn-txt {
		color: #fff;
		font-size: 20px;
		font-weight: 600;
		display: flex;
		justify-content: flex-start;
		align-items: center;
		margin-bottom: 0;
		img {
			padding-left: 2px;
		}
	}
}

.header-btm-div {
	display: flex;
	flex-direction: column;
	align-items: center;
}

@media (max-width: 786px) {
	.header-landing {
		padding: 30px 24px;

		.header-row-landing {
			.shifl-logo {
				height: 25px !important;
				padding-top: 0 !important;
			}
		}

		.header-p {
			font-size: 20px !important;
		}

		.sub-heading {
			font-size: 28px;
		}

		.sub-heading-two {
			max-width: 100% !important;
			font-size: 16px;
			line-height: 26px;
		}

		.landing-btn {
			margin-top: 8px;
			margin-bottom: 40px;
			width: 100%;
			justify-content: center;

			.left-btn {
				height: 42px;
				padding: 0px 14px !important;
				/* min-width: 150px !important; */
				display: flex;
				align-items: center;
				justify-content: center;
				border-radius: 4px !important;
				text-align: center;
				margin-right: 8px;

				.left-btn-txt {
					font-weight: 500;
				}
			}

			.right-btn {
				height: 40px;
				padding: 0px 14px !important;
				/* min-width: 140px; */
				display: flex;
				align-items: center;
				justify-content: center;
				border-radius: 4px !important;
				margin-left: 8px;

				.right-btn-txt {
					font-weight: 500;
				}
			}
		}
	}

	.dashboard-image-mac {
		margin-bottom: 30px;

		.image-mac {
			height: 280px;
			width: 400px;
		}
	}

	.header-btm-div {
		margin-top: 0 !important;
	}
}

@media (min-width: 2567px) {
	.header {
		margin-bottom: 40px;
	}
}
@media (min-width: 2500px) {
	.landing-btn {
		margin-bottom: 70px;
	}
}
